@import "minima";

#description {
    font-size: 0.6em;
    margin-left: 0.6em;
}

#photos {

.primary-photo {
    position: relative;
    background-color: rgba(0, 0, 0, 1);
    img {
        width: 100%;
        height: 20em;
        min-width: 100%;
        object-fit: cover;
    }
    margin: 1.5em 0;
    box-sizing: border-box;
    transition: all .3s ease;
    box-shadow: 2px 6px 25px rgba(0, 0, 0, 0.1);
    border: 1px solid #efefef;
}
.primary-photo:hover {    
    box-shadow: 2px 6px 30px rgba(0, 0, 0, 0.3);

    img {
        opacity: 0.8;
    }
}

h4 {    
    position: absolute; 
    bottom: 2em; 
    width: 100%;
    left: 0; 
    margin: 0;
}
h5 {
    margin: 0;
}
.primary-photo span{
    color: #efefef; 
    font: bold 24px/45px Helvetica, Sans-Serif; 
    letter-spacing: -1px;  
    background: rgb(0, 0, 0); /* fallback color */
    background: rgba(0, 0, 0, 0.5);
    padding: 0.7em;
    line-height: 1.5;
    display: inline-block;
    box-decoration-break: clone;    
 }
    .newest {
        font-size: 0.6em;
        color: rgb(209, 209, 209);
    }
    .photo {
        margin-bottom: 1.9em;
        font-size: 0.9em;
        display: inline-block;
        padding: 3em 3em 1.5em 3em;
        border: 1px solid #eee;
        background-color: #fff;
    }
    .photo-description {
        margin-top: 0.1em;

    }
    .photo-description a{
        border-bottom: 1px dotted #222;
        border-bottom: 1px dotted #a8a8a8;
        color: #a8a8a8;
    }

}

.mini-photos {
  display: inline-grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 1px;
  grid-column-gap: 1px;
}

#home-container {
}

.tile {
    border: 1px solid #e8e8e8;
    padding-right: 3vw;
    padding-left: 3vw;
    padding-bottom: 2em;
    padding-top: 1.5em;
    margin-bottom: 10px;
}


.home-photos {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 0.5em;
        grid-column-gap: 0.5em;


    img {

        object-fit: cover;
        min-width: 100%;
        max-height: 200px;
    }
}

.profile-pic {
    padding: 5px;
    border: 5px dotted #e8e8e8;
    float: right;
    margin-left: 4em;
    border-radius: 50%;
    transition: border-radius 0.6s linear, border-style 22s linear;
}
.profile-pic:hover {
    border-radius: 0;
    border-style: dashed;
    box-shadow: inset 0 0 100px rgba(255,255,255,0.50);
}

@include media-query($on-palm) {
    .profile-pic {
        float: none;
        margin: 1em;
    }
    .home-photos {
        grid-row-gap: 1px;
        grid-column-gap: 1px;
    }
    #photos .primary-photo img {
        height: 15em;
    }
    .mini-photos {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    #photos .photo {
        padding: 0 0 0.5em 0;
    }
    .tile {
        padding-top: 0.75em;
        padding-bottom: 1em;
    }
}